<template>
  <q-dialog v-model="show" persistent>
    <q-card class="form">
      <q-card-section class="text-center text-white text-bold bg-light-blue-8">
        Pendaftaran Awal
      </q-card-section>
      <q-card-section class="">
        <q-input v-bind="inputAtr" label="Nama Siswa Baru" v-model="user.name" ref="name" />
        <q-input
          v-bind="inputAtr"
          :rules="[cekIsi, cekEmail]"
          label="Alamat Email"
          v-model="user.username"
          ref="usrname"
        />
        <q-input v-bind="inputAtr" label="No HP (WA)" v-model="user.no_hp" mask="#############" ref="no_hp" />
      </q-card-section>

      <q-card-actions class="row q-pa-md justify-between">
        <q-btn outline v-bind="btnAtr" label="Batal" @click="$router.go(-1)" />
        <q-btn v-bind="btnAtr" label="Buat Akun" @click="createAccount" />
        <div class="row text-grey-8 q-pt-sm justify-center items-center full-width">
          Sudah punya akun?
          <q-btn flat color="primary" class="q-px-xs" dense no-caps @click="$router.push('/login')" label="login" />
        </div>
      </q-card-actions>
      <q-card-section v-show="isEmailSent" class="bg-positive text-white text-caption q-py-xs">
        <strong>Pendaftaran berhasil!</strong> Cek email untuk menemukan info username dan pembayaran
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import md5 from "md5";
export default {
  name: "Daftar",
  data: function() {
    return {
      isEmailSent: false,
      user: {},
      model: null,
      isPwd: true,
      remember: false,
      show: true,
      btnAtr: {
        color: "light-blue-8",
        noCaps: true,
        style: "width: 120px;",
      },
      inputAtr: {
        outlined: true,
        dense: true,
        lazyRules: "ondemand",
        rules: [this.cekIsi],
      },
    };
  },
  methods: {
    cekIsi(val) {
      return !!val || "Wajib diisi";
    },
    cekEmail(val) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.user.username.toLowerCase()) || "Email tidak valid";
    },
    createAccount() {
      this.$refs.name.validate();
      this.$refs.usrname.validate();
      this.$refs.no_hp.validate();
      if (this.$refs.name.hasError || this.$refs.usrname.hasError || this.$refs.no_hp.hasError) {
        // this.formHasError = true;
      } else {
        let rand = Math.random()
          .toString(36)
          .substring(6)
          .toLowerCase();

        this.user.password = md5(rand);

        this.$http.get("/check_account/" + this.user.username, {}).then((result) => {
          if (result.data.count > 0) {
            this.$q.notify({
              icon: "warning",
              color: "negative",
              message: "Email sudah terdaftar",
            });
          } else {
            this.$http.post("/create_account", this.user, {}).then((result) => {
              this.$http
                .post(
                  "/send_username/" + result.data + "/" + this.user.name + "/" + this.user.username + "/" + rand,
                  {}
                )
                .then((result) => {
                  console.log("sent");
                });
              let newData = {
                username: this.user.username,
                nama: this.user.name,
              };
              console.log(newData);
              console.log('id: ');
              console.log(result.data);
              this.$http.post("/init_pendaftaran/"+result.data+"/"+this.user.username, newData, {}).then((result) => {});
              this.$q.notify({
                icon: "done",
                color: "positive",
                message: "Pembuatan akun berhasil, Silahkan cek email anda",
              });
              // this.$router.push("/login");
              this.isEmailSent = true;
              this.user.name = "";
              this.user.username = "";
              this.user.no_hp = "";
            });
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  z-index: 2001;
  background-color: #e3f2f8;
}
.form {
  width: 300px;
}
label {
  padding-top: 10px;
  padding-bottom: 15px;
  color: #505050;
  font-weight: bold;
}
.bg-blur {
  width: 100vw;
  height: 100vh;
  position: absolute;
}
.fg-blur {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px) brightness(0.5);
  position: absolute;
}
</style>
