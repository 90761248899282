import { render, staticRenderFns } from "./BuatAkun.vue?vue&type=template&id=7885823d&scoped=true&"
import script from "./BuatAkun.vue?vue&type=script&lang=js&"
export * from "./BuatAkun.vue?vue&type=script&lang=js&"
import style0 from "./BuatAkun.vue?vue&type=style&index=0&id=7885823d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7885823d",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QInput,QCardActions,QBtn});
